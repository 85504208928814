import React from "react";
import { Event } from "../Event";

import { nextEvents } from "../../data/Events";

import "./events.scss";

export function UpcomingEvents() {
  const events = nextEvents();
  return (
    <div>
      {events
        .map(event => <Event key={event.id} event={event} showTags />)
        .reduce((prev, cur) => [
          prev,
          <hr key={`hr-${prev.length}`} className="mt-1 mb-1" />,
          cur
        ])}
    </div>
  );
}
