import React from "react";
import { FiftyFiftyLayout } from "../Grid";
import { Link } from "../Link";
import { Card } from "../Card";
import { ReactComponent as InfoIcon } from "./images/info-icon.svg";
import { ReactComponent as CallIcon } from "./images/call-icon.svg";

import "./contact.scss";

export function ContactCard() {
  return (
    <Card>
      <FiftyFiftyLayout>
        <div className="icon-row">
          <div className="icon-row__icon">
            <InfoIcon />
          </div>
          <div className="icon-row__text">
            <strong>Request Medical Information</strong>
            <p>
              Have a question about Spark products or therapeutic areas? You can{" "}
              <Link
                to="/contact-spark"
                analytics={{
                  label: "Request Medical Information",
                  category: "Calls to Action"
                }}
              >
                ask it here
              </Link>
              .
            </p>
          </div>
        </div>
        <div className="icon-row">
          <div className="icon-row__icon">
            <CallIcon />
          </div>
          <div className="icon-row__text">
            <strong>Call 1-866-647-7275</strong>
            <p>
              Talk with a Spark representative Monday through Friday, 8{" "}
              <span className="small-caps">AM</span> to 8{" "}
              <span className="small-caps">PM</span> ET.
            </p>
          </div>
        </div>
      </FiftyFiftyLayout>
    </Card>
  );
}
