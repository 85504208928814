import React from "react";
import {
  Accordion as RaaAccordion,
  AccordionItem as RaaAccordionItem,
  AccordionItemHeading as RaaAccordionItemHeading,
  AccordionItemButton as RaaAccordionItemButton,
  AccordionItemPanel as RaaAccordionItemPanel
} from "react-accessible-accordion";

import { Hr } from "../Hr";

import { ReactComponent as Chevron } from "./images/chevron.svg";

import "./accordion.scss";

/**
 * Component for building an Accordion control.
 *  Some functionality is impaired when multiple Accordions are loaded at once.
 *
 * @param {object} props
 * @param {any} props.children
 * @param {boolean} [props.allowMultipleExpanded=true]
 * @param {boolean} [props.allowZeroExpanded=true]
 * @param {number|number[]} props.preExpanded -  index(es) of panels to expand initially
 */
export function Accordion({
  allowMultipleExpanded = true,
  allowZeroExpanded = true,
  preExpanded = allowZeroExpanded ? [] : [0],
  children,
  dark
}) {
  const classNames = ["accordion", dark && "accordion--dark"];
  return (
    <RaaAccordion
      allowMultipleExpanded={allowMultipleExpanded}
      allowZeroExpanded={allowZeroExpanded}
      preExpanded={Array.isArray(preExpanded) ? preExpanded : [preExpanded]}
      className={classNames.filter(x => x).join(" ")}
    >
      {children}
    </RaaAccordion>
  );
}

/**
 * Component for building an Accordion control.
 *
 * @param {object} props
 * @param {any} props.children - should only contain phrasing content
 * @param {number} [props.ariaLevel=3] - use to specify the heading level, defaults to 3
 */
export function AccordionHeader({ children, ariaLevel = 3 }) {
  return (
    <RaaAccordionItemHeading aria-level={ariaLevel}>
      <RaaAccordionItemButton>
        <span className="accordion__title">{children}</span>
        <Chevron className="accordion__chevron" />
      </RaaAccordionItemButton>
    </RaaAccordionItemHeading>
  );
}

export function AccordionItem({ children, name }) {
  const classNames = ["accordion__item"];
  if (name) {
    classNames.push(`item-${name}`);
  }
  return (
    <RaaAccordionItem
      className={classNames.join(" ")}
      activeClassName="accordion__item accordion__item-expanded"
    >
      {children}
      <Hr hairline />
    </RaaAccordionItem>
  );
}

export function AccordionPanel({ children, ...raaProps }) {
  return (
    <RaaAccordionItemPanel {...raaProps}>
      <div className="accordion__panel-inner">{children}</div>
    </RaaAccordionItemPanel>
  );
}

export function AccordionFooter({ children }) {
  return <footer className="accordion__footer">{children}</footer>;
}
