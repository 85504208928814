import React from "react";
import { Event } from "../Event";
import { taggedEvents } from "../../data/Events";
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel
} from "../../components/Accordion";

import "./events.scss";
import { EVENT_TAGS } from "../../data/Constants";

export function EventAccordion() {
  const config = [
    [EVENT_TAGS.HEMATOLOGY],
    [EVENT_TAGS.OPHTHALMOLOGY],
    [EVENT_TAGS.NEUROLOGY],
    [EVENT_TAGS.LYSOSOMAL],
    [EVENT_TAGS.GENE_THERAPY]
  ];
  return (
    <Accordion dark>
      {config.map(tags => {
        const tag = tags[0];
        return (
          <AccordionItem key={tag}>
            <AccordionHeader>
              <h3>{tag}</h3>
            </AccordionHeader>
            <AccordionPanel>
              {taggedEvents(tags).map(event => (
                <Event key={event.id} event={event} />
              ))}
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}
