import React, { useEffect, useRef, useState } from "react";
import "./video.scss";

import videojs from "video.js";
import "video.js/dist/video-js.css";
import { dataLayerEvent } from "../../analytics";
import { NoReactSnap } from "../NoReactSnap";

const TRACKING_INTERVAL = 15;
export function Video({ sources, poster, display, title }) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const [trackedEvents, setTrackedEvents] = useState([]);

  useEffect(() => {
    const videoJsOptions = {};
    const NOOP = () => {};

    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) {
        return;
      }

      playerRef.current = videojs(videoElement, videoJsOptions, NOOP);
    }

    return () => {
      const player = playerRef.current;
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      if (display) {
        player.play();
      } else {
        player.pause();
      }
    }
  }, [display]);

  // track sends data to the Google Tag Manager data layer for video events,
  // doing sufficient bookkeeping so that the same event is not sent multiple
  // times.
  function track(event, uniqueValue) {
    // Keep record of events and return immediately if the event has already
    // been tracked.
    const key = `${event}_${uniqueValue || ""}`;
    if (trackedEvents.indexOf(key) !== -1) {
      return;
    }
    setTrackedEvents([key, ...trackedEvents]);

    // Push event to the data layer
    dataLayerEvent(event, {
      video_title: videoRef.current.title,
      video_current_time: Math.floor(videoRef.current.currentTime),
      video_duration: videoRef.current.duration
        ? Math.floor(videoRef.current.duration)
        : undefined,
      video_url: videoRef.current.currentSrc,
      video_percent:
        videoRef.current.currentTime && videoRef.current.duration
          ? Math.round(
              (videoRef.current.currentTime / videoRef.current.duration) * 100
            )
          : undefined,
      video_visible: document.visibilityState === "visible",
      video_muted: videoRef.current.muted
    });
  }

  const onPlay = () => {
    track("video_start");
  };
  const onTimeUpdate = () => {
    // Do nothing if time is 0 or time is not a TRACKING_INTERVAL
    var time = Math.floor(videoRef.current.currentTime);
    if (time === 0 || time % TRACKING_INTERVAL !== 0) {
      return;
    }

    // Track progress event
    track("video_progress", time);

    // If duration is not available return as we cannot check for the
    // complete event
    if (!videoRef.current.duration) {
      return;
    }

    // If the last interval has been reached track the complete event
    var lastTime =
      Math.floor(videoRef.current.duration / TRACKING_INTERVAL) * 15;
    if (time === lastTime) {
      track("video_complete");
    }
  };

  return (
    <NoReactSnap fallback={<img src={poster} alt="" />}>
      <video
        className="video-js vjs-theme-spark vjs-big-play-centered vjs-fluid"
        poster={poster}
        ref={videoRef}
        controls
        preload="auto"
        onPlay={onPlay}
        onTimeUpdate={onTimeUpdate}
        title={title}
      >
        {sources.map(source => (
          <source key={source.src} src={source.src} type={source.type} />
        ))}
      </video>
    </NoReactSnap>
  );
}

export function videoSources(
  prefix,
  sizes = ["360", "480"],
  types = ["webm", "mp4"]
) {
  const list = [];
  types.forEach(type => {
    sizes.forEach(size => {
      list.push({
        src: `${prefix}-${size}p.${type}`,
        type: `video/${type}`
      });
    });
  });
  return list;
}
