import React from "react";
import "./background-video.scss";

//add max iteration count?

export function BackgroundVideo({ sources, poster, title }) {
  return (
    <div className="background-video">
      <video
        className="background-video__video"
        playsInline
        autoPlay
        muted
        loop
      >
        {sources.map(source => (
          <source key={source.src} src={source.src} type={source.type} />
        ))}
      </video>
    </div>
  );
}
