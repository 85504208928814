import React from "react";

import { Card } from "../../components/Card";
import { Grid, Row, Col } from "../../components/Grid";
import { Page } from "../../components/Page";
import { ContactCard } from "../../components/Contact/ContactCard";
import { BackgroundVideo } from "../../components/BackgroundVideo";
import "./home.scss";
import aavStill from "./images/aav-still.png";
import { videoSources } from "../../components/Video";
import { UpcomingEvents } from "../../components/Event/UpcomingEvents";
import { EventAccordion } from "../../components/Event/EventAccordion";

export default function HomePage() {
  return (
    <Page
      className="home"
      molecules={["bottom", "center-right-2"]}
      title="SparkMedical.com® | Medical Information for US Health Care Professionals"
      description="SparkMedical.com® is a Spark Therapeutics website for US healthcare professionals to access medical, scientific, and educational resources."
      subject=""
      canonical="/"
    >
      <section className="aav-hero">
        <Grid>
          <Row>
            <Col xs={9}>
              <h1 className="white">Turning Genes into Medicine</h1>

              <p className="lede white">
                Sparkmedical.com® provides US healthcare professionals with
                medical, scientific, and educational resources related to
                advancing gene therapy research for rare genetic diseases.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="pt-1">
              <Card
                title={<>Medical &amp; Scientific Resources</>}
                link="/medical-and-scientific-resources"
                hero
                right
                color="light-teal"
                analytics={{
                  label: "Search Medical and Scientific Resources",
                  category: "Calls to Action"
                }}
              >
                Search for medical and scientific information summaries.
              </Card>
            </Col>
            <Col sm={6} className="pt-1">
              <Card
                title={
                  <>
                    Educational
                    <br /> Resources
                  </>
                }
                link="/educational-resources"
                hero
                right
                color="dark-teal"
                analytics={{
                  label: "Find Educational Resources",
                  category: "Calls to Action"
                }}
              >
                Browse featured videos, presentations, and more.
              </Card>
            </Col>
          </Row>
        </Grid>
        <BackgroundVideo
          sources={videoSources("/assets/aav-clip/aav-clip")}
          poster={aavStill}
          stopAfter={30000}
          title="AAV Clip"
        />
      </section>
      <section className="offset-cards">
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <ContactCard />
            </Col>
          </Row>
        </Grid>
      </section>
      <section style={{ zIndex: 1 }}>
        <Grid fluid>
          <Row className="pt-3 pb-1">
            <Col xs={12}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mr-2 pt-2">
              <h2>Upcoming Events</h2>
              <Card slim>
                <UpcomingEvents />
              </Card>
            </Col>
            <Col md={7} className="pt-2">
              <h2>Scientific and Medical Congresses</h2>
              <p>
                At Spark® Therapeutics, we’re striving to address unmet needs in
                rare genetic diseases. Here are some events relevant to our
                research.
              </p>
              <EventAccordion />
            </Col>
          </Row>
        </Grid>
        <Grid fluid>
          <Row>
            <Col md={6} className="pt-2 pb-1-xs pb-1-sm">
              <Card
                strong
                giant
                title={
                  <>
                    Spark Therapeutics
                    <br />
                    Medical Affairs Fellowship
                  </>
                }
                link="/about-spark/fellowship"
                analytics={{
                  category: "Internal Links",
                  label: "Clicked Medical Affairs Fellowship"
                }}
              />
            </Col>
            <Col md={6} className="pt-2 pb-1-xs pb-1-sm">
              <Card
                strong
                giant
                title={
                  <>
                    Grants & Investigator
                    <br />
                    Support
                  </>
                }
                link="/grants-and-investigator-support"
                analytics={{
                  category: "Internal Links",
                  label: "Clicked Grants & Investigator Support"
                }}
              />
            </Col>
          </Row>
        </Grid>
      </section>
    </Page>
  );
}
