import React from "react";

export const TA_HEMATOLOGY = "Hematology";
export const TA_OPHTHALMOLOGY = "Ophthalmology";
export const TA_NEUROLOGY = "Neurology";
export const TA_LYSOSOMAL = "Lysosomal Storage Disorders";
export const TA_HEMATOLOGY_AND_LYSOSOMAL =
  "Hematology and Lysosomal Storage Disorders";

export const THERAPY_AREAS = {
  HEMATOLOGY: TA_HEMATOLOGY,
  OPHTHALMOLOGY: TA_OPHTHALMOLOGY,
  NEUROLOGY: TA_NEUROLOGY
};

//These show on the card with the icons
export const RESOURCE_TAGS = {
  GENE_THERAPY: "Gene Therapy",
  POMPE: "Pompe",
  HEMOPHILIA: "Hemophilia",
  HEMOPHILIA_A: "Hemophilia A",
  ANGIOEDEMA: "Hereditary Angioedema",
  INHERITED_RETINAL_DISEASE: "Inherited Retinal Diseases",
  STARGARDT: "Stargardt Disease",
  HUNTINGTONS: "Huntington’s Disease",
  PATIENT: "Patient Materials",
  DISCUSSION: "Discussion Materials",
  CLINICAL_TRIALS: "Clinical Trials",
  RETINITIS_PIGMENTOTSA: (
    <>
      Retinitis Pigmentosa (<em>RPE65</em>)
    </>
  ),
  RETINITIS_PIGMENTOTSA_LEBER_CONGENITAL_AMAUROSIS: (
    <>
      Retinitis Pigmentosa/Leber Congenital Amaurosis (<em>RPE65</em>)
    </>
  )
};

export const DISEASE_STATES = {
  POMPE: "Pompe",
  HEMOPHILIA: "Hemophilia",
  HEMOPHILIA_A: "Hemophilia A",
  ANGIOEDEMA: "Hereditary Angioedema",
  INHERITED_RETINAL_DISEASE: "Inherited Retinal Diseases",
  STARGARDT: "Stargardt Disease",
  HUNTINGTONS: "Huntington’s Disease",
  RETINITIS_PIGMENTOTSA:
    "Retinitis Pigmentosa/Leber Congenital Amaurosis (RPE65)"
};

export const RESOURCE_ICONS = {
  GENE: "GENE",
  LIVER: "LIVER",
  RETINA: "RETINA",
  CNS: "CNS",
  BLOOD: "BLOOD"
};

export const RESOURCE_TYPES = {
  LINK: "link",
  APP: "app",
  DOCUMENT: "document",
  VIDEO: "video"
};

export const RESOURCE_SUPPLEMENTAL_TYPES = {
  CLINICAL_TRIALS: "Clinical Trials",
  PATIENT_SUPPORT: "Patient Support & Advocacy"
};

export const RESOURCE_EXPIRED_EXPIRATION_DATE = "2022-03-01";

export const EVENT_TAGS = {
  HEMATOLOGY: TA_HEMATOLOGY,
  OPHTHALMOLOGY: TA_OPHTHALMOLOGY,
  NEUROLOGY: TA_NEUROLOGY,
  LYSOSOMAL: TA_LYSOSOMAL,
  GENE_THERAPY: "Gene Therapy"
};

export const AREAS_OF_INTEREST = {
  "gene-therapies": "Gene Therapies",
  "genetic-testing": "Genetic Testing",
  hemophilia: "Hemophilia",
  irds: "Inherited Retinal Diseases (IRDs)",
  "liver-directed-diseases": "Liver-directed Diseases",
  "lysosomal-storage-disorders": "Lysosomal Storage Disorders",
  "neurodegenerative-diseases": "Neurodegenerative Diseases"
};

export const AREA_OF_INTEREST_DROPDOWN_OPTIONS = toDropdownOptions(
  AREAS_OF_INTEREST
);

export const PRODUCTS_AND_THERAPIES = {
  luxturna: (
    <>
      LUXTURNA<sup>&reg;</sup> <nobr>(voretigene neparvovec-rzyl)</nobr>
    </>
  ),
  "spk-7001": (
    <>
      Choroideremia <em>(SPK-7001)</em>
    </>
  ),
  "stargardt-disease": "Stargardt disease",
  "spk-8011": (
    <>
      Hemophilia A <em>(SPK-8011)</em>
    </>
  ),
  "spk-8016": (
    <>
      Hemophilia A with inhibitors <em>(SPK-8016)</em>
    </>
  ),
  "spk-1001": (
    <>
      CLN2/Batten disease <em>(SPK-1001)</em>
    </>
  ),
  "huntingtons-disease": "Huntington’s disease",
  "spk-3006": (
    <>
      Pompe disease <em>(SPK-3006)</em>
    </>
  )
};

export const US_STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY"
];

/**
 * States, translated for use as options in a Dropdown box.
 */
export const US_STATE_DROPDOWN_OPTIONS = toDropdownOptions(US_STATES);

export const TIMEZONES = [
  ["Pacific/Honolulu", "Hawaii"],
  ["America/Anchorage", "Alaska"],
  ["America/Los_Angeles", "Pacific"],
  ["America/Denver", "Mountain"],
  ["America/Chicago", "Central"],
  ["America/New_York", "Eastern"]
];

export const TIMEZONE_DROPDOWN_OPTIONS = toDropdownOptions(TIMEZONES);

function toDropdownOptions(values) {
  if (Array.isArray(values)) {
    return values.map(value => {
      if (Array.isArray(value)) {
        return { value: value[0], label: value[1] };
      }
      return { label: value, value };
    });
  }

  return Object.keys(values).map(value => ({
    label: values[value],
    value
  }));
}
