import React from "react";
import { Link } from "../Link";
import "./events.scss";

export function Event({ className, event, showTags }) {
  let Wrapper = "div";
  const titleClassNames = ["event__title"];
  const wrapperProps = {};
  if (event.link) {
    Wrapper = Link;
    wrapperProps.to = event.link;
    titleClassNames.push("event__title--link");
    wrapperProps.analytics = {
      category: "External Links",
      label: event.title
    };
  }

  return (
    <Wrapper
      className={["event", className].filter(x => x).join(" ")}
      {...wrapperProps}
    >
      <div className={titleClassNames.filter(x => x).join(" ")}>
        {event.title}
      </div>
      <div className="event__organization">{event.organization}</div>
      {showTags && (
        <div className="event__tags">
          {event.tags.map(tag => (
            <span key={tag} className="event__tag">
              {tag}
            </span>
          ))}
        </div>
      )}
    </Wrapper>
  );
}
