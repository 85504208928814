/*type Event = {
  id: string;
  title: string;
  organization?: string;
  description: string;
  link: string;
  tags: string[];
  date: Date;
}*/

import { EVENT_TAGS } from "./Constants";

const events = [
  {
    id: "1",
    title: "WFH World Congress",
    organization:
      "World Federation of Hemophilia & Canadian Hemophilia Society",
    description:
      "Explore the latest developments in managing and treating rare bleeding disorders.",
    link: "https://wfh.org/congress/",
    tags: [EVENT_TAGS.HEMATOLOGY],
    date: "2022-05-08"
  },
  {
    id: "2",
    title: "ISTH Congress",
    organization: "International Society on Thrombosis and Haemostasis",
    description:
      "Learn about ground-breaking thrombosis and hemostasis research from global experts.",
    link: "https://www.isth.org/page/ISTHCongresses",
    tags: [EVENT_TAGS.HEMATOLOGY],
    date: "2022-07-09"
  },
  {
    id: "3",
    title: "ASH Annual Meeting & Exposition",
    organization: "American Society of Hematology",
    description:
      "Attend one of the premier events in malignant and non-malignant hematology.",
    link: "https://www.hematology.org/meetings/annual-meeting",
    tags: [EVENT_TAGS.HEMATOLOGY],
    date: "2022-12-10"
  },
  {
    id: "4",
    title: "AAPOS Annual Meeting",
    organization:
      "American Association for Pediatric Ophthalmology & Strabismus",
    description:
      "Learn steps to improve the quality of medical and surgical eye care worldwide for children and for adults with strabismus.",
    link: "https://www.aapos.org/meetings/meetings-future",
    tags: [EVENT_TAGS.OPHTHALMOLOGY],
    date: "2022-03-23"
  },
  {
    id: "5",
    title: "ARVO Annual Meeting",
    organization: "Association for Research in Vision and Ophthalmology",
    description:
      "Join eye and vision scientists at all career stages to share the latest research findings and collaborate on innovative solutions.",
    link: "https://www.arvo.org/annual-meeting/",
    tags: [EVENT_TAGS.OPHTHALMOLOGY],
    date: "2022-05-01"
  },
  {
    id: "6",
    title: "ASRS Annual Meeting",
    organization: "American Society of Retina Specialists",
    description:
      "Explore the most recent scientific concepts, techniques, and research with retina specialists from around the world.",
    link: "https://www.asrs.org/annual-meeting/future-annual-meetings",
    tags: [EVENT_TAGS.OPHTHALMOLOGY],
    date: "2022-07-13"
  },
  {
    id: "7",
    title: "AAO Annual Meeting",
    organization: "American Academy of Ophthalmology",
    description:
      "Join the world’s largest association of eye physicians and surgeons for their annual meeting.",
    link: "https://www.aao.org/annual-meeting/meeting-information",
    tags: [EVENT_TAGS.OPHTHALMOLOGY],
    date: "2022-09-30"
  },
  {
    id: "8",
    title: "AAOpt Annual Meeting",
    organization: "American Academy of Optometry",
    description:
      "Gain access to clinically relevant CE courses and cutting-edge research in the clinical and vision sciences.",
    link: "https://aaopt.org/meetings/annual-meeting/",
    tags: [EVENT_TAGS.OPHTHALMOLOGY],
    date: "2022-10-26"
  },
  {
    id: "9",
    title: "Retina Society Annual Meeting",
    organization: "The Retina Society",
    description:
      "Engage in discussions and presentations centered on advancing knowledge and education in the field of retina.",
    link: "https://www.retinasociety.org/page/2/annual-meetings",
    tags: [EVENT_TAGS.OPHTHALMOLOGY],
    date: "2022-11-2"
  },
  {
    id: "10",
    title: "FFB VISIONS Conference",
    organization: "Foundation Fighting Blindness",
    description:
      "Join patients and advocates to learn about exciting advancements in blindness research.",
    link: "https://www.fightingblindness.org/events/visions-2024-503",
    tags: [EVENT_TAGS.OPHTHALMOLOGY],
    date: "2022-06-17"
  },
  {
    id: "11",
    title: "WORLDSymposium",
    organization: "WORLDSymposia",
    description:
      "Discuss the latest laboratory research related to lysosomal diseases.",
    link: "https://www.worldsymposia.org",
    tags: [EVENT_TAGS.NEUROLOGY, EVENT_TAGS.LYSOSOMAL],
    date: "2022-02-07"
  },
  {
    id: "12",
    title: "AANEM Annual Meeting",
    organization:
      "American Association of Neuromuscular and Electrodiagnostic Medicine",
    description:
      "Examine ways to improve patient care and advance the science of neuromuscular diseases and electrodiagnostic medicine.",
    link: "https://www.aanem.org/meetings/annual-meeting",
    tags: [EVENT_TAGS.NEUROLOGY],
    date: "2022-09-21"
  },
  {
    id: "13",
    title: "WMS Congress",
    organization: "World Muscle Society",
    description:
      "Catch up on the latest developments in neuromuscular diseases from around the world.",
    link: "https://www.worldmusclesociety.org/m/events/future-wms-congresses",
    tags: [EVENT_TAGS.NEUROLOGY, EVENT_TAGS.LYSOSOMAL],
    date: "2022-10-11"
  },
  {
    id: "14",
    title: "ASGCT Annual Meeting",
    organization: "American Society of Gene & Cell Therapy",
    description:
      "Gain insight from thought leaders in gene therapy research at this three-day hybrid conference.",
    link: "https://annualmeeting.asgct.org/",
    tags: [EVENT_TAGS.GENE_THERAPY],
    date: "2022-05-16"
  },
  {
    id: "15",
    title: "ESGCT Annual Meeting",
    organization: "European Society of Gene and Cell Therapy",
    description:
      "Explore the latest research in gene and cell therapy through keynote lectures, selected speakers, and more.",
    link: "https://www.esgct.eu",
    tags: [EVENT_TAGS.GENE_THERAPY],
    date: "2022-10-11"
  }
];
export default events;

export function taggedEvents(tags) {
  const filtered = events.filter(event =>
    tags.some(tag => event.tags.includes(tag))
  );
  return sortEventsByNextDate(filtered);
}

export function nextEvents(count = 2) {
  const sorted = sortEventsByNextDate(events);
  return sorted.slice(0, count);
}

function sortEventsByNextDate(events) {
  return events.sort((a, b) => nextDate(a) - nextDate(b));
}

function eventOccursInPast(event) {
  const today = new Date();
  const eventDate = new Date(event.date);
  return today.getMonth() > eventDate.getMonth();
}

function nextDate(event) {
  //increment year for events that occurred in a prior month this year
  //we assume that they will occur in the same month each year

  const today = new Date();
  const eventDate = new Date(event.date);

  if (eventOccursInPast(event)) {
    eventDate.setFullYear(today.getFullYear() + 1);
  }
  return eventDate;
}
